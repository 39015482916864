@import "../_variables.scss";

.notification {
  border: solid 1px $grey;
  border-radius: 0.25rem;
  background-color: $white;
  font-size: 18px;
  margin-bottom: 5px;
  padding: 15px;
  display: flex;

  > div:nth-child(1) {
    width: 75%;
  }

  > div:nth-child(2) {
    width: 25%;
    font-size: 16px !important;
    vertical-align: middle;
    text-align: end;
  }
  
  .notification-header {
    font-size: 22px !important;
    font-weight: 600 !important;
	line-height: 27px;
	margin-bottom:7px;
  }

  &.isRead {
    color: $lightGrey !important;
    background-color: $bg-disabled !important;
  }

  &.isNotRead {
    color: $lightBlack !important;
    background-color: $white;
  }

  img {
      max-width: 100%;
  }
  
  p {
	line-height: 24px;
	margin-bottom:0px;
	margin-top:0px;
  }
  
}

.notification-td {
  img {
    max-width: 100%;
  }
}

@media (max-width: 768px) and (max-height: 1024px) {
  .notification {
    flex-direction: column;

    > div:nth-child(1) {
      width: 100%;
    }

    > div:nth-child(2) {
      width: 100%;
    }
  }
}
