@import "../_variables.scss";

.btn-primary, .btn-info:hover {
  color: $white !important;
  background-color: $orange !important;
  border-color: $orange !important;
}

.btn-link {
  color: $orange !important;
  &.selected {
      color: $lightBlack !important;
  }
  &.filter {
      padding: 0 !important;
  }
}

.btn-filter > button {
    border-color: $grey !important;
    color: $lightBlack !important;
}

.btn-input-group {
    border-bottom-right-radius: 0 !important;
    border-top-right-radius: 0 !important;
}

.btn-info, .btn-primary:hover {
  color: $orange !important;
  background-color: $white !important;
  border-color: $orange !important;
}

.btn-secondary {
  background-color: $white !important;
  color: $orange !important;
  border-color: $orange !important;

  &.btn-primary {
    color: white !important;
    background-color: $orange !important;
    border-color: $orange !important;
  }

  &:hover {
    background-color: $lightOrange !important;
    color: $orange !important;
  }

  &.light {
    background-color: $lightOrange !important;
  }
}

.btn-success {
  background-color: $white !important;
  color: $greenActive !important;
  border-color: $greenActive !important;

  &.btn-primary {
    color: white !important;
    background-color: $greenActive !important;
    border-color: $greenActive !important;
  }

  &:hover {
    background-color: $lightGreen !important;
    color: $greenActive !important;
  }

  &.light {
    background-color: $lightGreen !important;
  }

  &:disabled {
    background-color: $lightGreen !important;
    color: $black !important;
  }
}


.div-link {
    color: #20a8d8 !important;
    text-decoration: none !important;
    background-color: transparent !important;

    :focus {
        outline: -webkit-focus-ring-color auto 1px !important;
    }

    &:hover {
        color: #167495 !important;
        text-decoration: underline !important;
    }
}

td .dropdown-menu {
  border-color: $orange !important;
  .dropdown-item{
      &:hover{
          background-color: $lightOrange !important;
          color: $black !important;
      }
  }
}

.find-btn {
  @media (max-width: 500px) and (max-height: 700px) {
    width: 100% !important;
  }

  @media (max-width: 769px) and (min-height: 700px) {
    width: 100% !important;
  }

  @media (min-width: 769px) {
    width: 67% !important;
  }
}

.clear-btn {
  @media (max-width: 500px) {
    width: auto !important;
  }
}

.dropdown-menu.show {
    overflow: initial !important;
}

.btn-arrow {
    width: 40px;
}

.fronted {
    z-index: 1055 !important;
}

.school-action {
  &.btn-group {
      display: block !important;
  }
  .dropdown-toggle::after {
      margin-left: 0 !important;
  }
}

.append-caret {
  .dropdown-toggle::after {
    margin-left: 0 !important;
  }
}
