// Variable overrides

$bg-color: rgba(#EAF0F5, 1);
$color-text: rgba(#708393, 1);
$white: rgba(#FFFFFF, 1);
$bg-disabled: rgba(#F5F5F5, 1);
$border-color: rgba(#23282c, 1);

$background: $bg-color !global;
$black: rgba(#232427, 1) !global;
$dark: $bg-color !global;
$orange: rgba(#ED7328, 1) !global;
$darkBlue: rgba(#2B4455, 1) !global;
$green: rgba(#ADDCD3, 1) !global;
$grey: rgba(#C8CED3, 1) !global;
$lightGrey: rgba(#708393, 1) !global;
$yellow: rgba(#FFD737, 1) !global;
$darkYellow: rgba(#ccab27, 1) !global;
$lightOrange: rgba(#FFF3CD, 1) !global;
$disabled: rgba(#EAF0F5, 1) !global;
$lightBlack: rgba(#2B4455, 1) !global;
$red: rgba(#F86C6B, 1) !global;
$lightGreen: rgba(#d4edda, 1) !global;
$lightDarkGreen: rgba(#c3e6cb, 1) !global;
$lightRed: rgba(#fdd8d8, 1) !global;

$greenActive: rgba(#3FB39D, 1) !global;

$orange-label: rgba(#FFCCAD, 1) !global;
$grey-label: rgba(#DDE2E6, 1) !global;
$yellow-label: rgba(#FFEDA7, 1) !global;
//// Spinkit
$body-bg: $bg-color !global;
$footer-bg: $white;
$body-color: lighten($white, 15%) !global;
$spinkit-spinner-color: $body-color;
$aside-menu-bg: $darkBlue;
$navbar-bg: $white;
$navbar-border: ( bottom: ( size: 0px, style: solid, color: $grey ) );
$breadcrumb-bg: $white;
$sidebar-bg: $darkBlue;
$sidebar-minimizer-bg: #23323C !global;
////$sidebar-borders: ( right: ( size: 1px, style: solid, color: $border-color ) ) !default;
//$tab-content-bg: $black;
//$nav-tabs-link-active-bg: $black;
//
$navbar-color: rgba($color-text, 1) !global;
$navbar-hover-color: rgba($orange, 1) !global;
$navbar-active-color: rgba($color-text, 1) !global;
$navbar-disabled-color: rgba(#128128, 1) !global;
//
$card-bg: $white;
$card-cap-bg: $white;
//$list-group-action-active-bg: $black;
//$list-group-bg: $black;
//
//$jumbotron-bg:$dark;
//$yellow: #ffc990 !global;
////$primary: #ffc990 !global;
$sidebar-nav-link-hover-bg: #3E5A69 !global; // #171617 !global;
$nav-tabs-link-active-bg: $white !global; // #171617 !global;
$sidebar-nav-link-active-bg: $orange !global; // #171617 !global;
////$list-group-border-color: $border-color !default;
////$list-group-hover-bg: $background;
////$list-group-disabled-color: $gray-600 !default;
////$list-group-action-color: $gray-200 !default;
////$list-group-action-active-color: $body-color !default;
//$list-group-action-active-bg: $background;
$nav-tabs-border-width: 0;
$nav-tabs-link-active-color: grey !global;
$nav-tabs-border-color: gray;
$nav-tabs-border-width: 1px;

:export {
  white: $white;
  black: $black;
  dark: $bg-color;
  orange: $orange;
  darkBlue: $darkBlue;
  green: $green;
  grey: $grey;
  lightGrey: $lightGrey;
  yellow: $yellow;
  darkYellow: $darkYellow;
  lightOrange: $lightOrange;
  disabled: $disabled;
  lightBlack: $lightBlack;
  red: $red;
  lightGreen: $lightGreen;
  lightDarkGreen: $lightDarkGreen;
  disabled: $disabled;
  green-active: $greenActive;
}
