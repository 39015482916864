@import "../_variables.scss";

.app-body.app-player{
    margin-top: 0 !important;
}

.player {
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 100%;
  //font-size: 18px !important;
  #task {
    margin-left: 30px;

    .form-control:disabled {
      background-color: $white !important;
    }
  }

  .ranger {
    height: 50px;
  }

  .main {
    margin: 0 !important;

    .container-fluid {
      padding-top: 0px !important;
    }
  }

  .container-fluid {
    padding: 0 !important;
  }

  .card {
    margin-bottom: 0 !important;
  }

  .custom-radio .custom-control-input:checked ~ .custom-control-label::after {
    margin-top: 5px !important;
  }

  .custom-radio .custom-control-label::before {
    margin-top: 5px !important;
  }

  .task-image {
    display: flex;
    align-items: normal;
    justify-content: center;
  }

  .task-text {
    p {
        display: inline !important;
    }
  }

  .variant-image {
    display: flex;
    align-items: normal;
  }
}

@media (max-width: 1025px/*991.98px*/ ) {
  .player {
    min-height: calc(100vh);
    padding: 20px;
    -webkit-user-select: none;
    -webkit-touch-callout: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    .card-body {
      padding: 0 !important;
    }

    .card {
      min-height: 96vh;
    }

    .task-image {
      //max-width: calc(60vw) !important;
      img {
        max-width: calc(60vw) !important;
      }
    }

    .task-text {
      font-size: 34px !important;
    }

    .task-block-name {
      font-size: 40px !important;
    }

    .answer-text {
      font-size: 34px !important;
    }

    .custom-radio .custom-control-label::before {
      width: 2rem !important;
      height: 2rem !important;
      margin-top: -5px !important;
      top: 0 !important;
    }

    .custom-radio .custom-control-label::after {
      width: 2rem !important;
      height: 2rem !important;
    }

    .custom-radio .custom-control-input:checked ~ .custom-control-label::after {
      margin-top: -8px !important;
      left: -1.45rem;
    }

    .custom-control {
      margin-right: 24px !important;
    }
  }

  #player {
    .app-body {
      margin-top: 0 !important;
    }

    #task {
      input {
        font-size: 34px;
        width: 450px;
      }
    }
  }

  .slider {
    width: 78% !important;
  }

  .slider-progress {
    width: 22% !important;
  }

  .list-tasks {
    //max-height: 542px;
  }

  .player-footer {
    height: 100px !important;
  }
}

@media (min-width: 1025px) {
  .player {
    min-height: calc(100vh);
    padding: 20px;

    .card-body {
      padding: 0 !important;
    }

    .card {
      min-height: 95vh;
    }

    .task-text {
      font-size: 34px !important;
    }

    .task-block-name {
      font-size: 40px !important;
    }

    .answer-text {
      font-size: 34px !important;
    }

    .custom-radio .custom-control-label::before {
      width: 2rem !important;
      height: 2rem !important;
      margin-top: -5px !important;
      top: 0 !important;
    }

    .custom-radio .custom-control-label::after {
      width: 2rem !important;
      height: 2rem !important;
    }

    .custom-radio .custom-control-input:checked ~ .custom-control-label::after {
      margin-top: -8px !important;
      left: -1.45rem;
    }

    .custom-control {
      margin-right: 24px !important;
    }

    #task {
      input {
        font-size: 34px !important;
        width: 450px !important;
      }
    }
  }

  .slider {
    width: 87% !important;
  }

  .slider-progress {
    width: 12% !important;
  }

  .btn-navigation, .btn-answer{
    button {
      font-size: 28px !important;
    }
  }

  .finish-btn {
    font-size: 28px !important;
  }

  .player-footer {
    height: 108px !important;
  }
}

@media (min-width: 1024px) and (min-height: 768px) {
  .player {
    min-height: calc(100vh);
    padding: 20px;

    .card-body {
      padding: 0 !important;
    }

    .card {
      min-height: 95vh;
    }

    .task-image {
      //max-width: calc(60vw) !important;
      img {
        max-width: calc(60vw) !important;
      }
    }

    .task-text {
      font-size: 34px !important;
    }

    .task-block-name {
      font-size: 40px !important;
    }

    .answer-text {
      font-size: 34px !important;
    }

    .custom-radio .custom-control-label::before {
      width: 2rem !important;
      height: 2rem !important;
      margin-top: -5px !important;
      top: 0 !important;
    }

    .custom-radio .custom-control-label::after {
      width: 2rem !important;
      height: 2rem !important;
    }

    .custom-radio .custom-control-input:checked ~ .custom-control-label::after {
      margin-top: -8px !important;
      left: -1.45rem;
    }

    .custom-control {
      margin-right: 24px !important;
    }
  }
  
  .slider {
    width: 90% !important;
  }

  .slider-progress {
    width: 10% !important;
  }
}

@media (max-width: 440px) and (max-height: 990px) {
  html:not([dir="rtl"]) .sidebar{
    margin-left: -100% !important;
  }

  html:not([dir="rtl"]) .sidebar-show .sidebar, html:not([dir="rtl"]) .sidebar-show .sidebar {
    margin-left: 0 !important;
  }

  .aside-task {
    font-size: 22px !important;
  }
  .aside-block {
    font-size: 28px !important;
  }

  .player {
    min-height: calc(100vh - 196px);
    margin-top: 72px !important;
    padding: 0 !important;

    .main {
      margin-top: 0 !important;

      .container-fluid {
        padding: 0 !important;
      }
    }

    .task-text {
      font-size: 20px !important;
    }

    .task-block-name {
      font-size: 24px !important;
    }

    .answer-text {
      font-size: 20px !important;
    }

    .custom-radio .custom-control-label::before {
      width: 2rem !important;
      height: 2rem !important;
      margin-top: 0 !important;
      top: 0 !important;
    }

    .custom-radio .custom-control-label::after {
      width: 2rem !important;
      height: 2rem !important;
    }

    .custom-radio .custom-control-input:checked ~ .custom-control-label::after {
      margin-top: -2.5px !important;
    }

    .custom-control {
      min-height: 2rem !important;
      margin-right: 8px !important;
    }

    #task {
      margin-left: 0px !important;

      input {
        font-size: 20px !important;
        width: 100% !important;
      }
    }

    .task-image {
      //width: calc(80vw);
      //
      img {
        width: calc(90vw);
        max-width: calc(90vw) !important;
      }
    }

    .answer-text {
      //margin-left: 8px !important;
      box-shadow: 1px 2px 2px $lightGrey;
      border-radius: 0.5rem;

      :active {
        background-color: $grey;
        border-radius: 0.5rem;
        box-shadow: none;
      }
    }

    .answer-text {
      p {
        padding: 3px 15px;
      }
    }

    .variant-image > img {
      //width: calc(75vw);
    }
  }
  #player {
    .app-body {
      padding-top: 0px !important;

      .main {
        padding-top: 20px !important;
        padding-bottom: 20px !important;
      }
    }

    .sidebar {
      flex: 0 0 100%;
      width: 100%;
    }
  }
  .slider {
    width: 70% !important;
    
  }

  .slider-progress {
    width: 30% !important;
  }

  .player-header {
      height: 72px !important;
  }

  .player-footer {
    height: 180px !important;
  }

  .player-info {
    min-height: 80px;
  }

  .footer-buttons {
    .btn-navigation {
      width: 100% !important;
      display: flex;
      justify-content: space-between;

      button {
        margin-bottom: 1rem;
        width: 50%;
      }
    }

    .btn-answer {
      width: 100% !important;

      button {
        width: 100% !important;
        margin-bottom: 1rem;
      }
    }
  }

  .player-footer {
    height: 240px !important;
  }
}

@media (max-width: 990px) and (max-height: 440px) and (orientation: landscape) {
  .player-header {
    height: 82px !important;
  }

  .player-info {
    margin-top: 4rem;
  }

  .player-footer {
    height: 150px !important;
  }
}

@media (max-width: 320px) and (max-height: 600px) {
  .player {
    //min-height: calc(100vh - 176px);
    min-height: calc(100vh - 196px);
    margin-top: 72px !important;
    padding: 0 !important;

    .main {
      margin-top: 0 !important;

      .container-fluid {
        padding: 0 !important;
      }
    }

    #task {
      margin-left: 0px !important;
    }

    .task-image {
      width: calc(90vw) !important;

      img {
        max-width: calc(90vw) !important;
        width: calc(90vw) !important;
      }
    }

    .variant-image > img {
      width: calc(75vw);
    }
  }

  #player {
    .app-body {
      padding-top: 0px !important;

      .main {
        padding-top: 20px !important;
        padding-bottom: 20px !important;
      }
    }
  }

  .slider {
    width: 55% !important;
  }

  .slider-progress {
    width: 45% !important;
  }

  .player-header {
    height: 72px !important;
  }

  .player-footer {
    height: 280px !important;
  }

  .footer-buttons {
    .btn-navigation {
      width: 100% !important;
      display: flex;
      justify-content: space-between;

      button {
        margin-bottom: 1rem;
        width: 50%;
      }
    }

    .btn-answer {
      width: 100% !important;

      button {
        width: 100% !important;
        margin-bottom: 1rem;
      }
    }
  }
}

.player-info {
  border-bottom: solid 1px $grey;
  padding: 1rem;
  min-height: 80px;
}

.player-body {
  border-bottom: solid 1px $grey;
  padding: 1rem;
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 20px !important;
}

.player-tasks {
  border-left: solid 1px $grey;
  //height: auto;
  height: inherit;
  width: 207px !important;
  padding: 1rem;
}

.player-footer {
  padding: 0.5rem 1rem;
  flex-shrink: 1;
  height: 80px;
}

.player-panel {
    width: calc(100% - 207px) !important;
    display: flex;
    flex-direction: column;
    &.mobile {
        width: 100% !important;
        //margin-top: -88px !important;
    }
}

.player-time {
    width: 100px;
}

.webcam {
  border: solid 1px $orange;
  border-radius: 0.5rem;

  &.mobile {
    border: none !important;
    border-radius: 0 !important;
    //background-color: grey; //todo: remove
  }
}

.input-range__slider {
  height: 2rem !important;
  margin-left: 0;
  margin-top: -1.25rem !important;
  top: 50%;
  width: 2rem !important;
  background: #3FB39D !important;
  border-color: #3FB39D !important;
}

.input-range__track--active {
  background: #3FB39D !important;
}

.input-range__label {
  z-index: 100 !important;
  color: white !important;
  font-size: 18px !important;
  font-family: "Geometria" !important;
}

.input-range__label--value {
  top: -1.15rem !important;
  left: 0.5rem !important;
}

.input-range__label--min {
    display: none !important;
}

.input-range__label--max {
    display: none !important;
}

.player-off {
  .player-body, .player-info, .player-tasks, .footer-buttons {
    pointer-events: none;
    opacity: 0.4;
  }
}


.finish-btn.btn-primary:disabled  {
  background-color: #fff3cd !important;
  color: #ed7328 !important;
}

.finish-btn {
  pointer-events: auto;
}

#player {
  .sidebar {
    z-index: 1024 !important;
    background-color: $white !important;
    border-right-color: $grey !important;
    height: 100vh !important;
    flex: 0 0 300px;
  }
}

.list-tasks {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 68vh;
  margin-left: 1rem;
  margin-top: 1rem;
}

.answer-text {
  .mjx-full-width{
      display: contents !important;
  }
}

.b-font {
    color: $black !important;
}

.task-variant {
  &.isRightAnswer {
    color: $greenActive !important;
    border-color: $greenActive !important;
  }

  &.isWrongAnswer {
    color: $red !important;
    border-color: $red !important;
  }
}

.task-helper {
  animation-duration: 1s;
  animation-fill-mode: both;
  animation-name: fadeIn;
}
