@import "../_variables.scss";

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 18px;
  height: 18px;
}

.lds-ellipsis div {
  position: absolute;
  top: 16px;
  width: 2px;
  height: 2px;
  border-radius: 50%;
  background: $black;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.h4 {
  .lds-ellipsis div {
    top: 14px;
    width: 4px;
    height: 4px;
  }

  .lds-ellipsis div:nth-child(1) {
    left: 9px;
  }

  .lds-ellipsis div:nth-child(2) {
    left: 6px;
  }

  .lds-ellipsis div:nth-child(3) {
    left: 12px;
  }

  .lds-ellipsis div:nth-child(4) {
    left: 20px;
  }
}

.lds-ellipsis div:nth-child(1) {
  left: 5px;
  animation: lds-ellipsis1 1s infinite;
}

.lds-ellipsis div:nth-child(2) {
  left: 2px;
  animation: lds-ellipsis2 1s infinite;
}

.lds-ellipsis div:nth-child(3) {
  left: 6px;
  animation: lds-ellipsis2 1s infinite;
}

.lds-ellipsis div:nth-child(4) {
  left: 16px;
  animation: lds-ellipsis3 1s infinite;
}

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(0);
  }
}

@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(8px, 0);
  }
}

.loader {
  border: 8px solid $grey;
  border-radius: 50%;
  border-top: 8px solid $lightGrey;
  width: 36px;
  height: 36px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

.loader-min {
  border: 4px solid $grey;
  border-radius: 50%;
  border-top: 4px solid $lightGrey;
  width: 24px;
  height: 24px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
