@import "../_variables.scss";

.switch-warning .switch-input:checked + .switch-slider {
  background-color: $orange !important;
  border-color: $grey !important;
}

.switch-warning .switch-input:checked + .switch-slider::before {
  border-color: $grey !important;
}

.switch-slider {
  background-color: $disabled !important;
  border: 1px solid $grey !important;
}

.switch-3d .switch-slider::before {
    border: 1px solid $grey !important;
}
