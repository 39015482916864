@import "../_variables.scss";

a.link {
  color: $orange !important;
  cursor: pointer;

  &.dashed {
    border-bottom: dashed 2px $orange;
  }

  &.selected {
    color: $black !important;
  }

  &.not-active {
    color: $orange !important;
    opacity: 0.7;

    &.dashed {
      border-bottom: dashed 2px $lightGrey !important;
      color: $lightGrey !important;
    }
  }
}

h1,h2,h3,h4,h5,h6 {
  a.link {
      font-size: 16px !important;
      font-weight: 300 !important;
  }
}

a.breadcrumbs {
  color: $color-text !important;
}
