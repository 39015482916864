@import "../_variables.scss";

.bs-tooltip-auto {
  .tooltip-inner {
    background-color: $lightGreen !important;
    font-size: 18px !important;
    color: $lightBlack !important;
  }

  .arrow::before {
    border-top-color: $lightGreen !important;
    border-bottom-color: $lightGreen !important;
    border-left-color: $lightGreen !important;
    border-right-color: $lightGreen !important;
  }
}
