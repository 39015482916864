@import "../_variables.scss";

.main {
  a {
    color: $orange;
  }
}

.navbar-nav > li {
  &.nav-item {
    .nav-link {
      height: 38px;
      margin-top: 16px;
      .link {
          color: $orange !important;
      }
    }

    .nav-link.active {
      height: 38px;
      margin-top: 16px;
      background: no-repeat center;
      background-size: contain;
    }
  }

  
}

.navbar-nav > li.nav-item.dropdown .nav-link .img-avatar {
  margin-top: -8px;
  width: 35px;
}

.nav > li {
  &.nav-item {
    .nav-link:hover {
      //background-color: #171617;
      color: rgba($white, 1);
    }
  }
}

.breadcrumb{
    border-bottom: none !important;
}

.breadcrumb-item a {
  color: $black !important; 
}

.breadcrumb-item.active a {
  color: $orange !important;
}
.breadcrumb-item.active {
  color: $color-text !important;
}

.breadcrumb-item + .breadcrumb-item::before {
  color: $color-text !important;
}

.sidebar-nav {
  .out.nav-item {
    .nav-link.active {
      background-color: transparent !important;

      .nav-icon {
        color: $orange !important;
      }
    }
  }
}

.sidebar .nav-link {
  .nav-icon {
    color: $orange !important;
  }

  &:hover,
  &:focus {
    .nav-icon {
      color: $white !important;

    }
  }
}

  .sidebar .nav-link.active .nav-icon {
    color: white !important;
  }

  .navbar-nav .nav-link.active {
    &:hover,
    &:focus {
      color: $orange !important;
    }
  }

.nav-title {
  font-family: 'Geometria';
  font-weight: normal !important;
  color: #FFFFFF;
  padding: .75rem 1rem !important;
  margin-top: 1rem !important;
  font-size: 80% !important;
  font-weight: 700 !important;
  text-transform: uppercase !important;
  transition: .25s !important;
}
  .sidebar .nav-title{
      font-size: 100% !important;
  }

.navbar-brand-full {
  height: 36px;
  margin-top: -14px;
}

@media (max-width: 991.98px) {
  .app-header .navbar-brand {
     margin-left: -84px !important;
  }
}

.nav-icon {
    &.none{
        display: none !important;
    }
}
