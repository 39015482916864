@import "../_variables.scss";

.modal-content{
    background-color: $white !important;
    color: $black !important;
    font-family: "Geometria";
}

.modal-header {
    border-bottom: solid 1px $grey !important;
}

.modal-footer {
  border-top: solid 1px $grey !important;
}

.modal-title {
    line-height: 1.5 !important;
}

.max-title {
  .modal-title {
    width: 100% !important;
  }
}

