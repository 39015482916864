@import "../_variables.scss";

.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
    //border-bottom: 3px solid #FFE900 !important;
    opacity: 1;
}

.list-group-item.active {
  //border-left: 3px solid #FFE900 !important;
  //opacity: 1;
  background-color: white !important;
  border-top: 1px solid #23282c !important;
  border-right: 1px solid #23282c !important;
  border-bottom: 1px solid #23282c !important;
  color: $color-text !important;
}

.nav-tabs > li {
  &.nav-item {
    &:hover {
      border: none !important;
    }

    .nav-link:hover {
      color: #e4e7ea;
      text-decoration: none;
      border-top: solid 1px $lightOrange !important;
      border-right: solid 1px $lightOrange !important;
      border-left: solid 1px $lightOrange !important;
      border-bottom: none !important;
    }
  }
}

.nav-tabs .nav-item .nav-link {
  margin-bottom: 0px !important;
  color: $orange !important;
  
}

.nav-tabs .nav-item .nav-link.active {
  color: $color-text !important;
  border-color: #C8CED3 #C8CED3 transparent #C8CED3 !important;
}

/*.nav > li {
    &.nav-item {
        .nav-link.active {
            //background-color: #171617;
            color: #FFE900;
        }
    }
}*/

.nav.nav-tabs {
  border-bottom: none !important;
}

.tab-content {
  border-radius: 0 0.25rem 0.25rem 0.25rem !important;
  margin-top: -1px !important;
  background-color: white !important;
  border-color: $grey !important;
}

.empty-back {
  background: $disabled !important;
}

@media (max-width: 440px) and (max-height: 990px) {
  .tab-pane.active.solution {
      padding: 0rem !important;
  }

  .student-results {
    &.tab-content {
      border-right: none !important;
      border-bottom: none !important;
      border-left: none !important;
    }
  }
}


