@import "../_variables.scss";


.input-group-text {
  background-color: #F0F3F5 !important;
  border: solid 1px $grey !important;
  color: #2B4455 !important;

  i {
    color: #2B4455 !important;
  }
}

input:-internal-autofill-selected {
  background-color: white !important;
}

.form-control {
  background-color: $white !important;
  color: $black !important;
  border-color: $grey !important;
  //font-weight: 600 !important;
}

.form-control:focus {
  background-color: $white !important;
  color: $black !important;
}

  .form-control::-webkit-input-placeholder {
    color: rgba($black, 0.8) !important;
  }

  .form-control:disabled {
    background-color: $disabled !important;
  }

  .form-control:focus {
    color: $lightBlack !important;
    border: none !important;
    box-shadow: 0 0 0 0.125rem rgba($orange, 0.35) !important;
  }

  .phone-form-control {
    .react-tel-input {
      display: flex !important;
      width: calc(100% - 40px) !important;
    }

    input.form-control {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      width: 100% !important;
    }
  }

  .react-tel-input {
    border: none !important;
    font-family: "Geometria" !important;
    //border-radius: 0.5rem !important;
    .form-control {
      background-color: $white !important;
      color: rgba($black, 0.8) !important;
      width: 100% !important;
      border: 1px solid $grey !important;
    }

    .flag-dropdown {
      background-color: $white !important;
      border: 1px solid $grey !important;
    }

    .flag-dropdown.open, .flag-dropdown.open .selected-flag {
      background: $white !important;
      border: 1px solid $grey;
    }

    .country-list .country:hover {
      background-color: $white !important;
    }

    .country-list {
      background-color: $white !important;
    }

    .country-list .country.highlight {
      background-color: $orange !important;
      color: $white !important;

      .dial-code {
        color: $white !important;
      }
    }

    .country-list .country .dial-code {
      color: $lightGrey !important;
    }
  }

  .custom-control {
      margin-right: -4px;
  }

  .custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
    background-color: $orange !important;
    border-color: $lightGrey !important;
    box-shadow: none !important;
  }

  .custom-control-input:checked ~ .custom-control-label::before {
    background-color: $orange !important;
  }

  .custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'><path fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/></svg>") !important;
  }

  .custom-checkbox .custom-control-input:checked:disabled ~ .custom-control-label::after {
    background-color: $grey !important;
  }

  .custom-radio .custom-control-input:checked ~ .custom-control-label::after {
    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'><circle r='3' fill='%23ED7328'/></svg>") !important;
  }
.right {
  &.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'><circle r='3' fill='%233FB39D'/></svg>") !important;
  }

  &.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
    border-color: $greenActive !important;
  }
}
.wrong {
  &.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'><circle r='3' fill='%23F86C6B'/></svg>") !important;
  }

  &.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
    border-color: $red !important;
  }
}

  .custom-radio .custom-control-input:checked ~ .custom-control-label::before {
    background-color: $white !important;
    border-color: $orange !important;
    box-shadow: none !important;
  }

  .custom-control-label::before {
    background-color: $white !important;
    border-color: $grey !important;
    box-shadow: none !important;
  }

  .error-field {
    border: solid 1px $red !important;
  }

  .error-select {
    .Select-control {
      border-color: $red !important;
    }
  }

  .input-feedback {
    color: $red !important;
  }

  .custom-control-label {
    cursor: pointer !important;
  }

  .phoneButton {
    padding-left: 0px !important;
  }

  .checkbox {
    &.bordered {
      border: solid 1px $grey;
      border-top-left-radius: 0.25rem;
      border-bottom-left-radius: 0.25rem;

      .custom-checkbox {
        margin-left: 0.5rem;
      }
    }
  }

  .custom-checkbox-filter {
    &.dropdown-toggle::after {
      margin-left: 0 !important;
      vertical-align: 0 !important;
    }
  }

  .input-group {
    width: auto !important;
    //  &.filter-checkbox {
    //    width: auto !important;
    //  }
  }

  .react-datepicker-wrapper {
    width: 100% !important;
  }

  .checkbox.bordered .custom-checkbox {
    margin: 0 0.25rem 0 0.5rem !important;
  }

.no-spin::-webkit-inner-spin-button, .no-spin::-webkit-outer-spin-button {
  -webkit-appearance: none !important;
  margin: 0 !important;
}

.no-spin {
  -moz-appearance: textfield !important;
}
