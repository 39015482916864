// Styles
// CoreUI Icons Set
@import '~@coreui/icons/css/coreui-icons.css';
// Import Flag Icons Set
@import '~flag-icon-css/css/flag-icon.min.css';
// Import Font Awesome Icons Set
@import '~font-awesome/css/font-awesome.min.css';
// Import Simple Line Icons Set
@import '~simple-line-icons/css/simple-line-icons.css';

// Import Main styles for this application
@import './scss/style.scss';

@import "~@coreui/coreui-pro/scss/themes/dark/coreui-dark.scss";

@font-face {
  font-family: 'Geometria';
  src: local('Geometria'), url(./fonts/Geometria/Geometria.woff) format('woff');
}

@font-face {
  font-family: 'Geometria Bold';
  src: local('Geometria Bold'), url(./fonts/Geometria/Geometria-Bold.woff) format('woff');
}
