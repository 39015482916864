@import "../_variables.scss";
@import 'tabs', 'cards', 'buttons', 'forms', 'links', 'navigations', 'switch', 'notifications',
'badges', 'tables', 'alerts', 'select', 'modal', 'editor', 'player', 'widgets', "loader", "tooltip";

.app{
    font-family: 'Geometria' !important;
}
html {
  scroll-behavior: smooth;
}

body {
  color: $lightBlack !important;
  appearance: none !important;
  -moz-appearance: none !important;
  -webkit-appearance: none !important;
}

footer {
  color: $lightGrey !important;
}

label{
    color: $lightBlack !important;
}

h1, h2, h3, h4, h5, h6 {
  color: $lightBlack !important;
  font-style: normal !important;
  line-height: 44px !important;
  letter-spacing: 0.214286px !important;
  appearance: none !important;
  -moz-appearance: none !important;
  -webkit-appearance: none !important;
}

h1 {
  font-weight: 700 !important;
  font-size: 35px !important;
  margin-bottom: 1rem !important;
}


.app-header {
  border-bottom: 1px solid $grey !important;
}

.app-footer {
  border-top: 1px solid $grey !important;
}



.height-100{
    height: 100vh !important;
}

.icon-info {
    color: #ffe900;
}

.va-middle {
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.image-upload > input {
    display: none;
}

.custom-avatar {
  vertical-align: middle;
  width: 36px !important;
  height: 36px !important;
  border-radius: 50%;
  position: relative;
}

.min-avatar {
    vertical-align: middle;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    position: relative;
}

.img-circle {
    border-radius: 50%;
}

img.img-circle {
    background: black;
}

.image-upload {
    z-index: 300;
}

.image-upload > label {
    font-size: 18px;
    cursor: pointer;
}

.avatar-upload {
    position: relative;
    max-width: 170px;

    .avatar-edit {
        position: absolute;
        left: 12px;
        z-index: 1;
        top: 136px;
        

        input {
            display: none;

            + label {
                &::after {
                    content: "\f030";
                    font-family: 'FontAwesome';
                    color: #FFF;
                    cursor:pointer;
                    position: absolute;
                    top: 10px;
                    left: 0;
                    right: 0;
                    text-align: center;
                    margin: auto;
                    font-size: 1.33333333em;
                }
            }
        }
    }

    .avatar-preview {
        width: 170px;
        height: 170px;
        position: relative;
        border-radius: 100%;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);

        > div {
            width: 100%;
            height: 100%;
            border-radius: 100%;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
        }
    }
}

  
  
  
  /*DatePicker*/
.react-datetime-picker__wrapper, .react-date-picker__wrapper, .react-time-picker__wrapper {
  border: none !important;

  input {
    color: $black !important;
  }

  select {
    color: $black !important;

    option {
      background-color: $white !important;
    }
  }

  svg {
    stroke: white !important;
  }
}

.react-datetime-picker--disabled, .react-date-picker--disabled, .react-time-picker--disabled {
  background-color: $disabled !important;
  color: $black !important;
}
.react-datepicker-popper {
  z-index: 100 !important
}
  /*DatePicker*/
  .row.zeroPadding {
    margin-left: -30px;
    margin-right: -30px;
    //margin-top: -14px;
    margin-bottom: 0px;
  }

  .spinner-sm {
    width: 16px;
    height: 16px;
    margin: 0.25rem;

    .sk-circle {
      width: 20px;
      height: 20px;
      margin: 0rem;
    }
  }

  .btn-brand i {
    line-height: 2.2rem !important;
  }

  @-webkit-keyframes autofill {
    to {
      color: #FFFFFF;
      background: #515b65;
    }
  }

  input:-webkit-autofill {
    -webkit-animation-name: autofill;
    -webkit-animation-fill-mode: both;
  }

  

  .clicable-icon {
    cursor: pointer;
  }

  .clicable-icon:hover {
    filter: brightness(80%);
  }

  .app-body {
    overflow-y: hidden !important;
  }

  

  .display-4 {
    font-size: 4rem !important;
  }

  @media (min-width: 1281px) {

    .main .container-fluid {
      max-width: 1280px !important;
    }


    .container-fluid {
      max-width: 1280px !important;
    }
  }

  @media (max-height: 768px) {
    .jumbotron {
      max-height: 250px !important;
    }

    .mt-tournament-name {
      margin-top: 10px !important;
    }

    .mb-tournament-jumbotron {
      margin-bottom: 2.25rem;
    }

    .mh-350 {
      min-height: 150px !important;
    }

    h3, h4, h5, h6 {
      line-height: 25px !important;
    }

    h1, h2 {
      line-height: 32px !important;
    }
  }

  @media (max-height: 835px) {
    .jumbotron {
      max-height: 300px !important;
    }

    .mt-tournament-name {
      margin-top: 10px !important;
    }

    .mb-tournament-jumbotron {
      margin-bottom: 2.25rem;
    }

    .mh-350 {
      min-height: 230px !important;
    }
  }

  @media (min-width: 576px) {
    .jumbotron {
      padding: 2rem 2rem !important;
    }
  }

  .linear-gradient {
    background-color: #9dc5c3;
    background-image: linear-gradient(315deg, #9dc5c3 0%, #5e5c5c 74%);
    height: 200px;
    opacity: 1;
  }

  td {
    vertical-align: middle !important;
  }

  .img-avatar-small {
    width: 32px !important;
    height: 32px !important;
  }

  .modal-backdrop.show {
    opacity: 0.8 !important;
  }


  .spinner-r1 {
    background: url('../../assets/img/loading.gif');
    background-size: cover;
    width: 128px;
    height: 128px;
    margin: $spinkit-spinner-margin;
    background-color: $lightOrange !important;
    border-radius: 1rem;
  }

  


  .card-header {
      border-bottom:0px;
  }

@media (max-width: 500px) {
    //.card{
    //    margin-bottom: 0px !important;
    //}
    //.card-body{
    //    padding-bottom: 0px !important;
    //}
    #btn-edit{
        margin-right: 0px !important;
    }

    #btns-tournament{
        text-align: right;
    }

    .search-row {
        margin-bottom: 0px !important;
    }

  .request-card-mobile {
    padding: 0 0.25rem !important;
  }
}

.search-row {
  margin-bottom: 0.25rem;
}

#loader {
    .modal-content {
        background-color: transparent !important;
        border: none !important;
        border-radius: 0 !important;
    }
    .loader-img{
        border-radius: 50em !important;
        max-width: 100%;
    }
}

.list-group-item.onlyRow {
    padding: 0rem 1.25rem !important;
}

/***chat**/

.sidebar{
    .btn-primary{
        .nav-link{
            color: black;
        }
    }
}


.container-fluid{
    &.min{
        padding-left: 10px !important;
        padding-right: 10px !important;
    }
}
.main {
    &.min {
        margin-top: 0 !important;
        margin-bottom: 2rem !important;
    }
}

.login-card {
  max-width: 478px !important;
}

.va-center{
    bottom: 0 !important;
    margin: auto !important;
}

.rbc-time-slot{
  color: black !important;
}

.modal-base{
  max-width: 90% !important;
}

.active-filter{
  text-decoration-line: underline;
}


.main .container-fluid {
    padding-top: 10px !important;
}

.client-form {
  border: solid 1px grey;
}

.no-hover {
  color: #20a8d8 !important;
  text-decoration: none !important;
}


.react-datepicker {
  button:focus {
    outline: none; 
  }
}
.rbc-toolbar .react-datepicker__navigation {
  padding: 0 !important;
  border: 0.45rem solid transparent !important;
}

button.react-datepicker__navigation:focus {
  outline-color: #F0F0F0 !important;
  button:focus {
    outline-color: #F0F0F0 !important;
  }
}

.react-datepicker-popper {
  left: -48px !important;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle,
.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before{
  //left: 136px !important;
}
.rbc-header {
    white-space:normal !important;
}


.w-98{
  width: 98% !important;
}

.w-85 {
  width: 85% !important;
}

.w-70 {
  width: 70% !important;
}

.common-grid{
  min-width: 98% !important;
}


.dropdown-menu-right {
    width: fit-content !important;
    border-color: $grey !important;
}

.dropdown-item {
  background-color: white !important;
  color: $lightGrey !important;
  border-color: $grey !important;

  &:hover {
    background-color: lightgrey !important;
    color: black !important;
    border-color: $grey !important;
  }
}


.contest {
  border: solid 1px $orange;
  border-radius: 0.5rem;
  margin-bottom: 1rem;
  //display: flex;
  //justify-content: space-between;
  padding: 0.5rem;
  background-color: $white;
  margin-left: auto;
  margin-right: auto;

  //.row{
  //    margin-left: 0px !important;
  //    margin-right: 0px !important;
  //}
}

.contest-info{
    width: 100%;
    padding: 0 1rem;
}

.contest-image {
    img {
      width: 180px;
      height: 160px;
    }
}

.category-image {
  img {
    width: 100%;
    min-height: 160px;
  }
}

.btn-min {
    button {
        padding: 0 0.5rem !important;
    }
}

@media (max-width: 360px) {
  .m-link {
      font-size: 11px !important;
  }
}

@media (max-width: 767px) {
  .contest {
    width: calc(100vw - 8%);
  }

  .contest-list {
    display: flex;
    flex-direction:column;
    align-items: center;
  }

  .contest-image {
    img {
      width: 100%;
      height: 100%;//160px;
    }
  }

  .category-image {
    img {
      width: 100%;
      height: 160px;
      
    }
  }
  .category {
      width: 325px !important;
  }
}

@media (max-width: 1025px) {
  .category {
    width: 325px !important;
  }
  .contest-select {
    margin-top: 8px;
  }
}

#taskPreview {
    //border: solid 1px $grey;
    //border-radius: 0.5rem;
    //padding: 15px 20px;
    //background-color: $white;
    //margin-top: 1rem;

    .form-check-inline{
        margin-right: 0 !important;
        margin-left: 0.65rem !important;
    }
}

//#new-variant {
//  border: solid 1px $grey;
//  border-radius: 0.25rem;
//  margin-bottom: 1.5rem;
//  padding: 5px 10px;
//  background-color: $white;
//  filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.2));
//}

.dzu-inputLabel {
  padding: 10px;
  font-size: 14px !important;
  font-weight: 400 !important;
  top: initial !important;
  bottom: initial !important;
  left: initial !important;
  right: initial !important;
  min-width: 200px !important;
}

.dzu-dropzone {
    overflow: hidden !important;
    min-height: 142px !important;
}

.task-preview-image {
  img {
    max-width: 100%;
    max-height: 200px;
  }
}

.text-end {
    text-align: end !important;
}

.answer-cell p {
    margin-top: auto !important;
    margin-bottom: auto !important;
}

.word-break {
    word-break: break-all;
}

.block-task {
  border: solid 1px $lightGrey;
  border-radius: 0.5rem;
  padding: 0.75rem 0.25rem;
  margin-left: 0.25rem;
  margin-right: 0.25rem;

  &.block-task-disabled {
    pointer-events: none;
    opacity: 0.7;
  }
}

div[data-rbd-drag-handle-draggable-id] {
  left: 0 !important;
  top: 0 !important;
  transition: opacity 0.2s cubic-bezier(0, 0, 0, 1) 0s !important;
  transform: translate(0px, 0px) !important;
}

.block-section {
  transform: none !important;
}

.modal-body .input-group-append button {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.hide-arrow{
    visibility: hidden;
}
.react-confirm-alert-overlay {
  background: rgba($lightGrey, 0.5) !important;
  z-index: 1100 !important;
}
.delete-dialog {
  background-color: $white;
  padding: 2rem;
  border-radius: 0.5rem;
}

.db-dialog {
  background-color: $white;
  padding: 2rem;
  border-radius: 0.5rem;
  min-width: 520px;
}

.part-counters > div {
    width: 20%;
  &.left-border {
      border-left: solid 1px $grey;
      padding-left: 0.75rem;
  }
}

.answer-text {
    word-break: break-word;
  p {
      margin-bottom: 0 !important;
  }
  display: block;
}

.task-completed {
  color: $lightBlack !important;
}

.task-not-completed {
  color: $grey !important;
}

.ok-checked {
  color: $greenActive !important;
}

.categories {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  
}

.category-image > img {
  min-height: 160px;
  max-height: 160px;
}

.category {
  width: 325px;
  margin: 0.25rem;
}

.category-block {
  //border: solid 1px $orange;
  border-radius: 0.5rem;
  margin-bottom: 1rem;
  padding-bottom: 1rem;
  //padding: 0.5rem;
  background-color: $white;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.2));
}

.category-info {
    padding: 0.25rem;
}

.category-select-button {
    //margin: 1rem 1rem;
    padding: 0 0.5rem;
    width: 100% !important;
}

.category-link {
    min-height: 50px;
}

.fa.active {
    color: $orange !important;
}

.contestant {
  background-color: $white;
  border-radius: 0.25rem;
  margin-bottom: 0.5rem;
  filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.2));
  height: 72px;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.request-info {
    display: flex;
    justify-content: space-between;
    border-top: solid $grey 1px;
    padding: 0 1rem !important;
    align-items: center;
    min-height: 44px;
}

.progress {
  background-color: #F0F3F5 !important;
  height: 0.3rem !important;
}


.full {
  .progress-bar {
    background-color: $greenActive !important;
  }
}
.small {
  .progress-bar {
    background-color: $red !important;
  }
}

.half {
  .progress-bar {
    background-color: $yellow !important;
  }
}

@media (max-width: 991.98px) {
  .contestant {
    .contestant-name {
      width: 20%;
    }

    .contestant-school-position {
      width: 10%;
    }

    .contestant-city-position {
      width: 10%;
    }

    .contestant-status {
      width: 15%;
    }

    .contestant-progress {
      width: 20%;
    }
  }
}
@media (min-width: 1025px) {
  .contestant {
    .contestant-name {
      width: 20%;
    }

    .contestant-school-position {
      width: 8%;
    }

    .contestant-city-position {
      width: 8%;
    }

    .contestant-status {
      width: 8%;
    }

    .contestant-progress {
      width: 20%;
    }
  }
}
@media (max-width: 1024px) and (max-height: 768px) {
  .contestant {
    .contestant-name {
      width: 25%;
    }

    .contestant-school-position {
      width: 8%;
    }

    .contestant-city-position {
      width: 8%;
    }

    .contestant-status {
      width: 8%;
    }

    .contestant-progress {
      width: 25%;
    }
  }
}
@media (max-width: 440px) and (max-height: 990px) {
  .contestant {
    min-height: 172px !important;
    padding: 0 !important;

    .contestant-info-block {
      padding: 10px 20px !important;
      border-right: solid 1px $grey;
    }

    .contestant-status-block {
      padding: 10px 20px !important;
    }

    .contestant-name {
      width: 100%;
      margin-bottom: 0.5rem !important;
    }

    .contestant-school-position {
      width: 100%;
      margin-bottom: 0.5rem !important;
    }

    .contestant-city-position {
      width: 100%;
      margin-bottom: 0.5rem !important;
    }

    .contestant-status {
      width: 100%;
      margin-bottom: 0.5rem !important;
    }

    .contestant-progress {
      width: 100%;
      margin-bottom: 0.5rem !important;
    }
  }
}

.w-33 {
  width: 33% !important;
}

.w-48 {
  width: 48% !important;
}

.w-30 {
  width: 30% !important;
}

.w-66 {
  width: 66% !important;
}

.point {
  width: 8px;
  height: 8px;
  background: #C4C4C4;
  border-radius: 0.5rem;
}

.selected-task{
    font-size: 16px !important;
    border-bottom: solid 1px $grey !important;
}

.popover {
  border-color: $grey !important;
  font-family: "Geometria" !important;
}
.popover-header {
    background-color: $bg-color !important;
    color: $black !important;
    border-bottom: none !important;
}
.popover-body {
  background-color: $white !important;
  color: $black !important;

  ul{
      padding-left: 20px !important;
  }
}

.format-info {
  ul {
    padding-left: 20px !important;
  }
}

.format-info-column{
    flex: 1;
    flex-basis: 100%
}

.format-bordered {
    border-bottom: solid 1px $grey;
}

.block-name {
    color: $black !important;
}

@media (max-width: 440px) and (max-height: 990px) {
  #solution {
    .task-image {
      //width: calc(80vw);
      //
      img {
        width: calc(75vw);
        max-width: calc(75vw) !important;
      }
    }

    .variant-image > img {
      width: calc(50vw);
    }
  }

  .taskPreview {
    padding: 0px 10px 10px 10px;
  }

  .teacher-report-dialog {
    min-width: initial !important;
    .teacher-report-container {
        display: block !important;
    }
  }
}

@media (max-width: 990px) and (max-height: 440px) {
  .teacher-report-dialog {
    min-width: initial !important;
    .teacher-report-container {
      display: block !important;
    }
  }
}

@media (max-width: 768px) and (max-height: 1024px), (max-width: 1024px) and (max-height: 768px) {
  .teacher-report-dialog {
    min-width: initial !important;

    .teacher-report-container {
      display: block !important;
    }
  }
}

#solution {
  .custom-radio .custom-control-input:checked ~ .custom-control-label::after {
    margin-top: 3px !important;
  }

  .custom-radio .custom-control-label::before {
    margin-top: 3px !important;
  }

  table, tr, td {
    border: 1px solid $lightGrey;
  }

  table td {
    word-break: break-word;
  }
}

.taskPreview {
  border: solid 1px $grey;
  border-radius: 0.5rem;
  padding: 10px 15px;
  background-color: $white;
  margin-top: 1rem;
  font-size: 18px !important;
}

.answer-text > p {
    margin-bottom: 0 !important;
}

.phone-footer{
  flex: 0 0 120px !important;
  bottom: 0 !important;
}

.payment-footer {
  flex: 0 0 320px !important;
  bottom: 0 !important;
}

.small-h {
  line-height: 30px !important;
}

.input-group {
  flex-wrap: nowrap !important;
}

.solution-answer-text {
  width: 14%;
  text-align: center;
  height: 24px;

  p {
    margin: 0 !important;
    //display: flex !important;
    //align-items: center !important;
    //justify-content: center !important;
  }
}

.solution-answer-index {
  min-width: 30px;
  text-align: center;
  vertical-align: middle;
  //display: flex !important;
  //align-items: center !important;
  //justify-content: center !important;
}

.isRight {
    background-color: $lightGreen;
}
.isError {
  background-color: $red;
  color: $white;
}
.isEmpty {
  background-color: $grey;
}

.mobile-contest {
  border: 1px solid $grey;
  border-radius: 0.25rem;
  padding: 0.5rem;
  margin-bottom: 0.75rem;
  background-color: $white;
  //box-shadow: 1px 2px 2px $lightGrey;

  :active {
    //background-color: $grey;
    border-radius: 0.5rem;
    box-shadow: none;
  }
}

.coordinator-payment-comment {
  display: none;
}

.coordinator-payment-form {
  textarea {
    min-height: 100px;
  }
}

@media (min-width: 1281px) {
  .coordinator-payment {
    display: flex;
    flex-wrap: wrap;
    flex-grow: 2;
  }

  .coordinator-payment-form {
    flex: 60%;
    width: 100%;
  }

  .coordinator-payment-requests {
    flex: 40%;
    width: 100%;

    .col-lg-4, .col-lg-6 {
      flex: 0 0 100% !important;
      max-width: 100% !important;
    }

    .purposeOfPayment-label {
      display: none;
    }
  }

  .coordinator-payment-buttons {
    width: 100%;
  }

  .coordinator-payment-comment {
    display: block;
  }

  .coordinator-payments-comment {
      display: none;
  }

  .teacher-request-info-center {
    display: none !important;
  }

  .teacher-request {
    display: flex;
    height: 100%;

    @at-root .container-fluid {
      height: 100%;
      padding-right: 20px !important;
    }

    @at-root .animated {
      height: 100%;
    }
  }

  .teacher-request-header {
      display: flex;
      flex-direction: column;
  }

  .teacher-request-info-right {
    height: 100%;
    max-width: 250px !important;
    min-width: 250px !important;
    width: 100%;
    flex: 20% 1 auto;
    margin-bottom: 0 !important;
    min-height: 615px;
    display: flex !important;
  }

  .teacher-request-info {
    width: calc(100% - 250px);
    margin-right: 20px;

    .card.request-contestants {
      margin-bottom: 0 !important;
    }
  }

  .teacher-variant-participation {
    flex-direction: column;
    .format-info{
        max-width: 100% !important;
    }
  }

  // .main .container-fluid {
  //   //max-width: 1440px !important;
  //   //max-width: 100% !important;
  // }

  // .container-fluid {
  //   max-width: 1180px !important;
  // }
}

textarea[name=purposeOfPayment] {
  min-height: 100px;
}

@media (max-width: 1280px) {
  .teacher-request-info-right {
    display: none !important;
  }
}

tr.payment-table {
  td:nth-child(2) {
    white-space: pre-line !important;
  }
}

.variant-buttons {
    min-width: 36px !important;
}

#certificate-constructor {
  display: flex;
  &.full{
    flex-direction: column;
  }

  .certificate-constructor__form {
    width: 50%;
    &.full{
      width: 100%;
    }
  }

  .certificate-constructor__preview {
    width: 50%;
    &.full{
      width: 100%;
    }
  }
}

.request-info-counter-block {
  display: flex;

  .request-info-counter-block__description {
    width: 75% !important;
  }

  .request-info-counter-block__links {
    width: 25% !important;
  }
}

@media (max-width: 450px) {
  .request-info-counter-block {
    display: flex;
    flex-direction: column;

    .request-info-counter-block__description {
      width: 100% !important;
    }

    .request-info-counter-block__links {
      width: 100% !important;
    }
  }
}

@page {
  size: 7in 9.25in;
  margin: 27mm 16mm 27mm 16mm;
  page-break-after: always;
  page-break-before: always;
}

#blocksData_userVariant {
  .card {
    width: 100%;
    min-width: 210mm;
    min-height: 297mm;
    padding: 0;
    border: none !important;
    filter: drop-shadow(0px 0px 0px $white) !important;
    border-radius: 0 !important;
    page-break-before: always;

    .card-body {
      margin: 17mm 17mm 17mm 17mm;
    }
  }

  input {
    font-size: 24px !important;
  }

  font-size: 24px !important;
}

@media print {
  @page {
    size: A4;
    width: 210mm;
    min-height: 297mm;
    height: 100%;
    padding: 20mm;
    margin: 11mm 17mm 17mm 17mm;
  }

  #blocksData_userVariant {
    input {
      font-size: 24px !important;
    }

    .user-variant-block {
      //page-break-after: auto;
      //break-inside: avoid;
      .user-variant-task {
        break-inside: avoid;
        page-break-after: auto;
      }
    }

    .answer-blocks {
      //break-inside: avoid;
      page-break-after: auto;
      display: block;
    }

    .answer-block {
      break-inside: avoid-page;
      page-break-after: auto;
      display: block;
    }

    .task-text-preview {
      break-inside: avoid;
      page-break-after: auto;
      display: block;
    }

    .img-answer {
      break-inside: avoid;
      page-break-after: auto;
    }
  }
     
}
#blocksData_view {
  background-color: $white !important;
}

.fa-file-o:before, .fa-file-text-o:before, .fa-trophy:before, .fa-gift:before {
  color: $orange !important;
}

svg[role=img] > path {
    color: $orange !important;
}

.result-contestant {
    border: solid 1px $grey;
    padding: 10px;
    margin-bottom: 5px;
    border-radius: 0.25rem;
}

.new-contestants {
    border-bottom: solid 1px $grey;
    border-top: solid 1px $grey;
    margin: 15px 0;
    padding: 15px 0;
}

.mt-30 {
    margin-top: 30px;
}

.mt-16 {
  margin-top: 16px;
}

.mt-20 {
  margin-top: 20px;
}

.font-12 {
    font-size: 12px !important;
}
.font-14 {
  font-size: 14px !important;
}
.font-16 {
  font-size: 16px !important;
}

.font-24 {
  font-size: 24px !important;
}

.font-28 {
  font-size: 28px !important;
}

.font-36 {
  font-size: 36px !important;
}

.m-task-variant {
    margin-left: -60px;
}

.text-through {
  text-decoration: line-through;
}

.yt-block {
    background-image: url('../../assets/img/youtube-000.jpg');
    background-size: cover;
}

.success-message {
    color: $greenActive !important;
}

.preview {
  background-image: url('../../assets/img/news3.svg');
  background-repeat: no-repeat;
  background-size: contain;
  width: 80px;
  height: 100%;
  position: absolute;
  margin: -12px 0 0 -20px;
  border-bottom-left-radius: 0.25rem;
  border-top-left-radius: 0.25rem;
  
}

.margin-preview {
    margin-left: 80px;
	font-size: 1rem !important;
}

.teacher-create-variant {
  display: flex;
}

.teacher-create-variant_tags {
  min-width: 200px;
  font-size: 14px;
  color: $orange;
  padding: 0 5px;
  margin: 0 5px;
  background-clip: border-box;
  border: none;
  border-radius: 0.25rem;
  max-height: 950px;
  overflow: auto;
}

.teacher-create-variant_tasks {
    width: 100%;
}

.teacher-create-variant_selectedTag {
  background-color: $orange;
  color: $white;
  padding: 6px;
  border-bottom-left-radius: 0.25rem;
  border-top-left-radius: 0.25rem;
  text-wrap: none;
  white-space: nowrap;

  &.mobile {
    white-space: normal;
  }
}

.teacher-create-variant_tags-mobile {
  position: absolute;
  z-index: 1200;
  background-color: $white;
  min-width: 200px;
  height: 92% !important;
  max-height: 150% !important;
  font-size: 14px;
  color: $orange;
  padding: 0 5px;
  margin-left: -5px;
  background-clip: border-box;
  border: none;
  border-radius: 0.25rem;
  max-height: 950px;
  overflow: auto;
}

.not-visible {
  visibility: hidden;
}

.teacher-create-variant {
  display: flex;
}

.teacher-create-variant_tags {
  min-width: 200px;
  font-size: 14px;
  color: $orange;
  padding: 0 5px;
  margin: 0 5px;
  background-clip: border-box;
  border: none;
  border-radius: 0.25rem;
  max-height: 950px;
  overflow: auto;
}

.teacher-create-variant_tasks {
    width: 100%;
}

.teacher-create-variant_selectedTag {
  background-color: $orange;
  color: $white;
  padding: 6px;
  border-bottom-left-radius: 0.25rem;
  border-top-left-radius: 0.25rem;
  text-wrap: none;
  white-space: nowrap;

  &.mobile {
    white-space: normal;
  }
}

/*.teacher-create-variant_tags-mobile {
  position: absolute;
  z-index: 1200;
  background-color: $white;
  min-width: 200px;
  height: 104% !important;
  max-height: 104% !important;
  font-size: 14px;
  color: $orange;
  padding: 0 5px;
  margin-left: -5px;
  background-clip: border-box;
  border: none;
  border-radius: 0.25rem;
  max-height: 950px;
  overflow: auto;
}*/

.not-visible {
  visibility: hidden;
}

.search-archive-item {
  width: 100%;
  border-radius: 0.25rem;
  border: solid 1px $grey;
  background-color: $white;
  display: flex;
  padding: 5px 10px;
  margin: 5px 0;

  .search-archive-contest {
    width: 30%;
  }

  .search-archive-participant {
    width: 60%;
  }
}

.category-exception {
    margin: 5px 0px !important;
    border: solid 1px $grey;
    border-radius: 0.25rem;
}

svg[role=img].black > path {
  color: $lightGrey !important;
}

.label-green {
  color: darkcyan;
}

.field-set {
  border: solid 1px $grey;
  border-radius: 0.05rem;
  position: relative;
  padding: .5em;
  width: fit-content !important;
}

legend {
  padding: 0 0.25em 0 0.25em !important;
  font-size: 16px !important;
  width: auto !important;
  margin-bottom: 0 !important;
}

.mb-6 {
    margin-bottom: 5rem !important;
}

.mb-7 {
  margin-bottom: 7rem !important;
}

.mb-8 {
  margin-bottom: 9rem !important;
}

.rub {
  line-height: 5px;
  width: 0.4em;
  border-bottom: 1px solid #000;
  display: inline-block;
}

.field-updated{
    border-color: $black !important;
    div[class$=-control] {
        border: solid 1px $black;
    }
}

.contest-diff {
    .row > hr {
        margin-top: 0.25rem !important;
        margin-bottom: 0.25rem !important;
    }
}

.d-disabled {
  pointer-events: none;
  opacity: 0.7;
}

.teacher-report-dialog {
  background-color: $white;
  padding: 2rem;
  border-radius: 0.5rem;
  min-width: 900px;

  .teacher-report-container {
    display: flex;
    justify-content: space-around;
  }

  .tag-block {
    background-color: lemonchiffon !important;
    border: solid 1px $grey;
    width: 100%;
    font-size: 20px;
    padding: 2px 5px;
    border-radius: 0.25rem;
    cursor: pointer;
    margin-bottom: 2px;
  }

  .tag-warning {
    color: $red !important;
  }
}

.teacher-report {
    background: $white;
    padding: 20px 20px;
    height: 100%;
}

.divider {
  //height: calc(100);
  border-left: 1px solid $grey;
}


.row-contest::-webkit-scrollbar {
  height: var(--scrollbar-size);
  width: var(--scrollbar-size);
}

.row-contest::-webkit-scrollbar-track {
  background-color: var(--scrollbar-track-color);
}

.row-contest::-webkit-scrollbar-thumb {
  background-color: var(--scrollbar-color);
  /* Add :hover, :active as needed */
}

.row-contest::-webkit-scrollbar-thumb:vertical {
  min-height: var(--scrollbar-minlength);
}

.row-contest::-webkit-scrollbar-thumb:horizontal {
  min-width: var(--scrollbar-minlength);
}

.img-wrapper {
  min-height: 100vh;
  position: relative;
  overflow-x: auto;
  overflow-y: auto;
}

.img-wrapper > div {
  position: relative;
  height: 100% !important;
}
