@import "../_variables.scss";

.card {
  border: none !important;
  filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.2));

  &.card-requests, &.card-statements {
    margin-bottom: 0 !important;
  }
}

.switch-warning .switch-input:checked + .switch-slider {
    background-color: #ffe900 !important;
    border-color: #C9B805 !important;
}

.card-header {
  font-family: Geometria;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 21px !important;
  /* identical to box height, or 150% */
  letter-spacing: 0.214286px;
  color: #2B4455;
  border-bottom: solid 1px $grey !important;
}

.card-footer {
  border-top: solid 1px $grey !important;
}


.card-header.standard {
    font-size: 0.875rem !important;
}

.card-header .nav-tabs .nav-link.active {
  color: #e4e7ea !important;
  background: #2f353a !important;
}

.card-header .nav-tabs .nav-link {
  color: #e4e7ea !important;
  padding: 0.25em 1em 0.25em 1em !important;
}

@media (max-width: 769px) {
  .card-body.full {
    padding: 0.5rem !important;
  }

  .modal-footer.fix-btns > :not(:last-child) {
    margin-right: 0 !important;
  }

  .modal-footer.fix-btns > :not(:first-child) {
    margin-left: 0 !important;
  }
}

@media (max-width: 500px) {
  .card {
    margin-bottom: 8px !important;
  }
  .card-body.min {
    padding: 0.75rem 1.25rem !important;

    &.request-card-mobile {
      padding: 0 !important;
    }
  }
}

.card-body.min{
    padding: 0.75rem 1.25rem !important;
}


$switch-height: 21px !global;
$switch-width: 32px !global;
