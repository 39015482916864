@import "../_variables.scss";

.alert-warning {
  color: $black !important;
  background-color: $lightOrange !important;
  border-color: $orange !important;
  white-space: pre-line !important;
}

.alert-success {
  color: $black !important;
  background-color: $lightGreen !important;
  border: solid 1px $lightDarkGreen !important;
}

.alert {
    &.min{
        margin: 0 !important;
        padding: 0.45rem 0.75rem !important;
    }
}


