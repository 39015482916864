@import "../_variables.scss";

.pagination {
  float: left !important;
}

//table.table {
//    overflow: auto !important;
//}

.react-bs-table table {
  table-layout: initial !important;
}

#series-table{
  .react-bs-table table {
    table-layout: initial !important;
  
    th:nth-child(2) {
      padding-left: 0 !important;
    }
  }
}

.react-bs-table-container {
  width: auto !important;
  cursor: pointer;
  //overflow-x: auto !important;
  &.no-pointer {
    cursor: auto !important;
  }
}

.react-bs-table-pagination {
  .row > div:nth-child(1) {
    display: none !important;
  }

  .row > div:nth-child(2) {
    padding: 0px !important;
  }
}

.react-bs-table-pagination {
  margin: 5px !important;
  padding: 0.25em 1em !important;
}

.table td,
.table th {
  white-space: normal !important;
  text-overflow: initial !important;
}

.page-item {
  .page-link {
    color: $orange !important;
    background-color: $white !important;
    border-color: $grey !important;

    &:focus {
      box-shadow: 0 0 0 0.1rem rgba($orange, 0.25);
    }
  }

  &.active {
    .page-link {
      color: $white !important;
      background-color: $orange !important;
      border-color: $orange !important;
    }
  }
}

.table {
  color: $black !important;
}

.react-bs-table-pagination {
  padding: 1em 1em;
}

.react-bs-table-container .table.table-bordered {
  border-bottom: 0px;
}

.react-bs-table.react-bs-table-bordered {
    border-left: none !important;
    border-right: none !important;
    border-bottom: none !important;
    border-top: solid 2px $grey !important;
    border-radius: 0 !important;
}

@media (max-width: 769px) {
  .react-bs-table.react-bs-table-bordered {
    border-top: solid 0px $grey !important;
  }

  .table.table-bordered td {
    border-top: solid 1px $grey !important;
    border-bottom: solid 1px $grey !important;
  }

  .react-bs-table-pagination {
    .row > div:nth-child(2) {
      //padding: 0px 16px !important;
      //margin-left: auto;
      //margin-right: auto;
      //width: auto !important;

      flex: 0 0 100% !important;
      max-width: 100% !important;

      .pagination{
          width: 100%;
          justify-content: center;
      }

      //display: flex !important;
      //justify-content: center;
      //max-width: 100%;
      //flex: 0 0 100%;
    }
  }
}

.table.table-bordered td {
  border-left: none;
  border-right: none;
  border-top: solid 2px $grey;
  border-bottom: solid 1px $grey;
}

//.table.table-bordered tr {
//  border-top: solid 1px $grey;
//  border-left: none !important;
//  border-right: none !important;
//  border-bottom: solid 1px $grey;
//}

.react-bs-table-container .table.table-bordered th {
  background-color: $white;
}

.react-bs-container-header.table-header-wrapper .table {
  margin-bottom: 0px;
  border: 0px;
  color: $black !important;
}

.react-bs-container-header.table-header-wrapper .table thead th {
  border: 0px;
}

.react-bs-container-body .table-striped tbody tr:nth-of-type(odd) {
  background-color: $bg-color;
  border: 0px;
}

.table-bordered th {
  vertical-align: middle !important;
}

.table-bordered td:active, .table-bordered td:focus {
  outline-style: none;
}

.table-hover tbody tr:hover, .react-bs-container-body .table-striped tbody tr:hover {
  color: $black;
  //background-color: rgba(0, 0, 0, 0.075);
}

.react-bs-table-tool-bar > div.row {
  margin-left: 0px;
  margin-right: 0px;
  padding-left: 0px;
  padding-right: 0px;

  .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
  .col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8,
  .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm, .col-sm-auto, .col-md-1, .col-md-2, .col-md-3,
  .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12,
  .col-md, .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7,
  .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg, .col-lg-auto, .col-xl-1, .col-xl-2,
  .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11,
  .col-xl-12, .col-xl, .col-xl-auto {
    padding-right: 8px !important;
    padding-left: 8px !important;

    @media (max-width: 769px) {
      .card-body &.btn-group-panel {
        padding-left: 8px !important;
        padding-right: 8px !important;
        margin-bottom: 0.3rem !important;
      }

      .card-body &.find-block {
        padding-left: 0px !important;
        padding-right: 2px !important;
        margin-bottom: 0.3rem !important;
      }

      .tab-content &.btn-group-panel {
        padding-left: 2px !important;
        padding-right: 2px !important;
        margin-bottom: 0.3rem !important;
      }
    }
  }
}

@media (max-width: 500px){
  .react-bs-table-tool-bar > div.row {
    margin-left: 0px;
    margin-right: 0px;
    padding-left: 0px;
    padding-right: 0px;
  }
}

.react-bs-table {
  overflow-x: auto !important;
}

.td-overflow {
  overflow: inherit !important;
}

.react-bs-container-body {
  overflow: inherit !important;
  min-height: 320px !important;
}

.table-min {
  overflow: auto !important;

  .react-bs-container-body {
    min-height: initial !important;
  }
}

.react-bs-table-container .react-bs-table-search-form {
  margin-top: 0.1rem;

  .input-group {
    .input-group-append {
      button {
        border-top-left-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
      }
    }
  }

  @media (max-width: 500px) {
    .row > div {
      margin-bottom: 0.3rem !important;
    }
  }
}

.react-bs-table-tool-bar > .row {
    align-items: center;
}

.btn-group-panel {
  button {
    margin-top: 0.1rem !important;
  }

  @media (max-width: 769px) {
    .row > div {
      margin-bottom: 0.3rem !important;
    }
  }
}

.header-hidden {
    display: none !important
}

tr.tr-accent {
  border-left: solid 4px #3FB39D !important;
  border-bottom-left-radius: 0.25rem !important;
  border-top-left-radius: 0.25rem !important;
}



.react-bs-table-tool-bar > div.row .col-12.pxi-0 {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.react-bs-table-search-form {
  .input-group {
    .form-control {
      border-bottom-right-radius: 0.25rem !important;
      border-top-right-radius: 0.25rem !important;

      &:focus {
        border-bottom-right-radius: 0.25rem;
        border-top-right-radius: 0.25rem;
        box-shadow: 0 0 5px rgba($orange, 0.35);
        clip-path: inset(-5px -5px -5px -5px);

        @at-root .input-group {
          .input-group-append {
            border-bottom-right-radius: 0.25rem;
            border-top-right-radius: 0.25rem;

            &.search-focused {
              box-shadow: 0 0 0 0.125rem rgba($orange, 0.35);
              clip-path: inset(-5px -5px -5px 0px);
              border-bottom-right-radius: 0.25rem;
              border-top-right-radius: 0.25rem;
              border-bottom-left-radius: 0;
              border-top-left-radius: 0;
              z-index: 5 !important;
              border-left: none !important;
            }
          }
        }

        &.search-focused {
          clip-path: inset(-5px 0px -5px -5px) !important;
          border-bottom-right-radius: 0 !important;
          border-top-right-radius: 0 !important;
        }
      }
    }
  }

  .loader {
    margin-top: 5px;
    margin-left: 2px;
    margin-right: 2px;
    width: 24px !important;
    height: 24px !important;
    border: 4px solid $grey;
    border-top: 4px solid $lightGrey;
  }
}

.coordinator-table-requests, .coordinator-table-statements {
  input.form-control {
    padding: 0.375rem 0.3rem !important;
  }
}


.tr-warning {
    background-color: $lightRed !important;
}

.coordinator-table-statements, .coordinator-table-requests {
  table td input {
    min-width: 50px !important;
    max-width: 50px !important;
  }
}

.td-column-combine-2 {
  display: flex !important; //table-caption;
  white-space: pre !important;
}

@-moz-document url-prefix() {
  .react-bs-table {
      height: auto !important;
  }
}

.coordinator-table-requests, .coordinator-table-statements {
  .react-bs-container-body {
    max-height: calc(100vh - 250px);
  }
}

.big-table {
  .th-c {
    text-align: center;
  }

  .th-l {
    text-align: left;
  }

  .react-bs-container-body {
    //overflow: hidden !important;
    //overflow-y: auto !important;
    overflow: inherit !important;
  }

  .react-bs-container-header {
    //overflow: initial !important
    overflow: hidden !important;
  }
}

.diff-category-table {
  font-size: 18px;

  tr {
    padding: 5px;

    td {
      padding: 0 5px;

      i {
        font-size: 16px;
      }

      
    }

    td:first-child {
      margin-right: 5px;
    }
  }
}

.category-table {
    width: 100%;
    
}

.border-t {
  border-top: solid 1px $grey;
}

.table-bordered {
    border: solid 1px $grey;
}

.td-va-top {
    vertical-align: top !important;
}
