@import "../_variables.scss";

.ql-editor {
    background-color: $white !important;
    //font-weight: 600;
}

.ql-toolbar {
    background-color:$lightGrey !important;
}

.ql-formats {
  button.ql-active, button:hover {
    color: $orange !important;

    svg line, svg path, svg polyline, svg rect, svg circle {
      stroke: $orange !important;
    }
  }
}

.MJXc-display{
    display: contents !important;
    text-align: start !important;
}

.ql-container.ql-snow{
    min-height: 100px !important;
}

.editor-toolbar {
    background-color: $disabled !important;
    //border-color: $grey !important;
}

.editor-field {
  background-color: $white !important;
  border-color: $grey !important;
}

.editor-wrapper {
  border: solid 1px $grey !important;
  border-radius: 0.125rem;
}

.ql-tooltip.ql-editing {
  z-index: 1000 !important;
}
