@import "../_variables.scss";

.badge {
  font-size: 12px !important;
  border-radius: 20px !important;
  font-weight: 700 !important;
  line-height: 15px !important;
  font-style: normal !important;
  padding: 0.2rem 0.5rem !important;
}

.app-header {
  .badge {
    font-size: 12px !important;
    margin-top: -19px !important;
  }
}

.badge-orange {
  color: $black !important;
  background-color: $orange-label !important;

  .pressed {
    background-color: $lightOrange !important;
  }
}

.badge-green {
  color: $black !important;
  background-color: $green !important;
}

.badge-grey {
  color: rgba(43, 68, 85, 0.6) !important;
  background-color: $grey-label !important;
}

.badge-white {
  color: $lightBlack !important;
  border: solid 0.5px $lightGrey !important;
  background-color: $white !important;
}

.badge-yellow {
  color: $black !important;
  background-color: $yellow-label !important;

  &.pressed {
    background-color: $darkYellow !important;
  }
}
