@import "../_variables.scss";


.widget-block {
  height: 232px;
  min-width: 280px;
  max-width: 300px;
  width: 100%;
  background: $disabled;
  border-radius: 0.25rem;
  filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.2));
  cursor: pointer;
  font-weight: 600;
  margin-bottom: 0.775rem;
}

@media (max-width: 290px) and (max-height: 660px) {
  .widget-block {
    min-width: 240px !important;
    max-width: 300px !important;
    width: 100% !important;
  }
  .widget-points-value {
    top: 7px !important;
  }

  .widget-points-text {
    top: 7px !important;
  }

  .widget-solved-tasks-img {
    right: -30px !important;
  }
}

  //.widget-points-img {
  //  position: fixed;
  //  bottom: 0px;
  //  right: 0px;
  //}

  .widget-points-value {
    position: relative;
    font-size: 56px;
    line-height: 70.45px;
    letter-spacing: 0.21px;
    top: 30px;
    left: 10px;
  }

  .widget-points-text {
    position: relative;
    font-size: 24px;
    line-height: 24px;
    letter-spacing: 0.21px;
    top: 30px;
    left: 16px;
  }

.widget-points-img {
  //position: fixed;
  //bottom: 0px;
  //right: 0px;
  background-image: url("../../assets/img/widgets/points.svg");
  background-position-x: 74px;
  background-position-y: 52px;
  background-size: initial;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
  //-webkit-transform: translateZ(0);
}

  .widget-points-value {
    position: relative;
    font-size: 56px;
    line-height: 70.45px;
    letter-spacing: 0.21px;
    top: 30px;
    left: 16px;
  }

  .widget-points-text {
    position: relative;
    font-size: 24px;
    line-height: 24px;
    letter-spacing: 0.21px;
    top: 30px;
    left: 16px;
  }



.widget-solved-tasks-img {
  background-image: url("../../assets/img/widgets/solved-tasks.svg");
  background-position-x: 0px;
  background-position-y: 0px;
  background-size: initial;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
}

@media (max-width: 340px) and (max-height: 600px) {
  .widget-solved-tasks-img {
    background-position-x: -10px;
  }
}

  .widget-solved-tasks-value {
    position: relative;
    font-size: 56px;
    line-height: 70.45px;
    letter-spacing: 0.21px;
    top: 30px;
    text-align: center !important;
    z-index: 10;
  }

  .widget-solved-tasks-text {
    position: relative;
    font-size: 24px;
    line-height: 24px;
    letter-spacing: 0.21px;
    top: 100px;
    text-align: center !important;
  }

.widget-solved-task-link {
  text-align: center;
  top: 110px;
  position: relative;
}

.widget-certificate-text {
  position: relative;
  font-size: 24px;
  line-height: 24px;
  letter-spacing: 0.21px;
  text-align: center;
  top: 96px;
  //left: 16px;
}

.widget-order-certificate-text {
  position: relative;
  font-size: 24px;
  line-height: 24px;
  letter-spacing: 0.21px;
  text-align: center;
  top: 106px;
  line-height: 1.5;
  //left: 16px;
}

.widget-tag-text {
  position: relative;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.21px;
  text-align: center;
  top: 10px;
  //left: 16px;
}

.widget-tag-value {
  position: relative;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.21px;
  text-align: center;
  top: 20px;
  margin: 0 5px;
}

.widget-tag-adValue {
  position: relative;
  font-size: 18px;
  letter-spacing: 0.21px;
  text-align: center;
  top: 20px;
}

.widget-tag-img {
  height: 100%;
  width: 100%;
}


.widget-gift-text {
  position: relative;
  font-size: 24px;
  line-height: 24px;
  letter-spacing: 0.21px;
  text-align: center;
  top: 64px;
  //left: 16px;
}
